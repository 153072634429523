<template lang="html">

  <section class="decorations-awards">
    <hero-global img="/img/hero/condecoraciones-premios.svg" title="Condecoraciones" subtitle="Condecoraciones y premios" />
    <div class="container">
      <div class="column-center">
        <p class="title-grand text-center color-primary">
          26
        </p>
        <b class="title-decoration-bottom font-family-frank-ruhl text-center">Condecoraciones y Premios</b>
      </div>
      <div :class="[
                    showFeatureAll
                      ? 'boton-features-div'
                      : 'boton-features-div inactive'
                  ]">
        <template v-if="showFeatureAll">
          <p class="title -intro features contraer">Contraer todo</p>
        </template>
        <template v-else>
          <p class="title -intro features contraer">Expandir todo</p>
        </template>
        <div class="boton-azul" @click="toggleFeature">
          <div class="boton-circulo"></div>
        </div>
      </div>
      <ul class="list-item-dates">
       
        <template v-for="(year, key) in Object.values(arrayCompletYears)" :key="key">
        <li class="item-list" v-if="getDataAdwards(year).items">
          <span class="date">{{year}}</span>
          <div class="content-date">
            <p class="title" @click="toggleFeatureItem(year)">Condecoraciones y Premios en {{year}}</p>
            <ul :class="[showFeatureAll ? 'feature' : 'feature inactive']" :data-year="year">
              <li v-for="(adward, key) in getDataAdwards(year).items" :key="key">{{adward.name}}</li>
            </ul>
          </div>
        </li>
        </template>
        <div class="column-center">
          <button class="btn-white" @click="getMoreYear" v-if="showMore" title="Ver más" alt="Ver más"> Ver más</button>
        </div>
      </ul>
    </div>
    <carrousel-2 class="container m-bottom-40-px" />
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'decorations-awards',
    props: [],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.arrayCompletYears = this.arrayYears
      this.store.loadAwards({ arrayYears: this.arrayYears, maxYear: this.maxYear })
    },
    data() {
      return {
        showFeatureAll: true,
        showMore: true,
        maxYear: new Date().getFullYear(),
        arrayCompletYears: [],
        minYear:1999
      }
    },
    methods: {
      toggleFeature: function () {
        this.showFeatureAll = !this.showFeatureAll
      },
      toggleFeatureItem: function (val) {
        if (val != null) {
          document.querySelector('.feature[data-year="' + val + '"]').classList.toggle("inactive")
        }
      },
      range: function range(start, stop = undefined, step = 1) {
        const startArray = stop === undefined ? 0 : start;
        const stopArray = stop === undefined ? start : stop;
        return Array.from({ length: (stopArray - startArray) / step + 1 }, (_, i) => startArray + (i * step)).reverse();
      },
      getMoreYear: function name(maxyear) {
        this.maxYear = this.maxYear - 11
        // this.arrayCompletYears = this.arrayCompletYears.concat(this.arrayYears)
        if (this.maxYear <= 2002) {
          this.showMore = false
        }
        this.store.loadAwards({ arrayYears: this.arrayYears, maxYear: this.maxYear })
      },
      getDataAdwards: function (year) {
        if (this.store.awards[year] && this.store.awards[year].items.length > 0) {
          return this.store.awards[year]
        }
        return "Lo sentimos no hay resultados"

      }
    },
    computed: {
      arrayYears() {
        return this.range(this.minYear, this.maxYear)
      },
      // minYear() {
      //   if (this.minYear <= 1999) {
      //     return 1999
      //   } else {
      //     return this.maxYear - 10

      //   }
      // },
    }
  }


</script>

<style scoped lang="scss">
  .decorations-awards {
    .hero-content {
      height: 300px;
    }
  }
</style>